import React from "react";
import styled from 'styled-components';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const RichTextContainer = styled.article`
  background-color: #7600FF50;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.75s ease;
  opacity: ${({ showRichBg }) => (showRichBg ? "1" : "0")};
  height: ${({ showRichBg }) => (showRichBg ? "100%" : "0%")};
  bottom: ${({ showRichBg }) => (showRichBg ? "0%" : "10%")};
  transform: ${({ showRichBg }) => showRichBg ? "translateY(0%)" : "translateY(105%)"};

  z-index: 100 !important;
`;

const GatsbyRichText = styled.aside`
  width: 90vw;
  max-width: 1000px;
  height: 86vh;

  position: fixed;
  top: 4rem;
  left: 50%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background-color: #FFFFFF;

  transition-property: opacity, bottom, transform;
  transition-duration: 0.75s;
  transition-timing-function: ease;

  opacity: ${({ showRichText }) => (showRichText ? "100%" : "0")};
  bottom: ${({ showRichText }) => (showRichText ? "3rem" : "100%")};
  transform: ${({ showRichText }) => showRichText ? "translate(-50%, 0)" : "translate(-50%, 105%)"};
`;
const RichTextSection = styled.section`
  padding: 1rem 2rem;

  p { 
    color: #000000;
    font-size: 1.4rem;
  }

  @media screen and (max-width: 820px){
    p { 
      font-size: 1rem;
      line-height: 14pt;
    }
  }
  @media screen and (max-height: 667px){
    padding: 1rem 1rem;
    p {
      line-height: 12pt;
      font-size: 0.8rem;
    }
  }
`

const CloseProject = styled.button`
  position: absolute;
  height: 32px;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  text-decoration: none;
  font-family: "Perroquet-Regular", sans-serif;
  font-size: 10pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  place-content: center;
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 0 0.5rem;
  background-color: rgba(255, 255, 255, 0.88);
  cursor: pointer;

  -webkit-animation: fadeinFlip ease-out 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinFlip ease-out 0.4s; /* Firefox < 16 */
  -ms-animation: fadeinFlip ease-out 0.4s; /* Internet Explorer */
  -o-animation: fadeinFlip ease-out 0.4s; /* Opera < 12.1 */
  animation: fadeinFlip ease-out 0.4s;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: color .5s ease-in-out, background-color .5s ease-in-out;

  &:hover {
    background: rgba(119, 0, 255, 0.88);
    border: 1px solid rgba(119, 0, 255, 0.88);
    color: white;
  }

  @media screen and (max-height: 667px){
    font-size: 0.8rem;
  }
`;

const ProjetRichText = ({ data, toggleText, showRichText, showRichBg }) => {
  return (
    <RichTextContainer showRichBg={showRichBg}>
      <GatsbyRichText toggleText={toggleText} showRichText={showRichText}>
        {data.contentfulProjects.richText1 && (
          <RichTextSection>
            {documentToReactComponents(
              JSON.parse(data.contentfulProjects.richText1.raw)
            )}
          </RichTextSection>
        )}
        {data.contentfulProjects.richText2 && (
          <RichTextSection>
            {documentToReactComponents(
              JSON.parse(data.contentfulProjects.richText2.raw)
            )}
          </RichTextSection>
        )}
        {data.contentfulProjects.richText3 && (
          <RichTextSection>
            {documentToReactComponents(
              JSON.parse(data.contentfulProjects.richText3.raw)
            )}
          </RichTextSection>
        )}

        <CloseProject onClick={() => toggleText()}>Revenir au projet</CloseProject>
      </GatsbyRichText>
      
    </RichTextContainer>
  )
}

export default ProjetRichText