import React, { useState, createContext, useContext, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"

import LogotypeMini from "../LogoTypes/LogotypeMini"
import Icon from "../LogoTypes/Logotype"
import * as AnimationsStyle from "./projet.module.scss"

const ProjetHeader = styled.header`
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: #f1f1f1;
  padding: 0.5rem;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ProjetNav = styled.nav`
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
`

const Logo = styled(Link)`
  padding: 0.118rem 0.5rem 0.1rem 0.5rem;
  height: 32px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7600ff;
  margin-right: 0.3rem;

  svg path {
    fill: white;
  }
`

const NomProjet = styled.div`
  border-radius: 1.75px;
  background-color: rgba(46, 47, 50, 0.88);
  margin-right: 0.3rem;
  height: 32px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12pt;
  font-family: "Perroquet-Regular", Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 0.88);
  background-color: #7600ff;

  text-decoration: none;
  overflow-wrap: break-word;
  transition: all 0.5s ease-in-out;
`

const AnchorCategory = styled(Link)`
  padding: 0 0.5rem;
  background-color: rgba(46, 47, 50, 0.88);
  color: black;
  margin-right: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  background-color: rgba(255, 255, 255, 0.88);
  white-space: nowrap;
  border-radius: 10rem;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;

  &:hover {
    background-color: rgba(46, 47, 50, 0.88);
    color: white;
  }

  @media screen and (max-width: 840px) {
    display: none;
  }
`

const AnchorClose = styled(Link)`
  z-index: 2000;
  border: 0px solid #7600ff;
  background-color: rgba(255, 255, 255, 0.88);
  border-radius: 10rem;
  color: #000;
  width: 33px;
  height: 33px;
  display: grid;
  text-decoration: none;
  place-content: center;
  font-family: sans-serif;

  -webkit-animation: fadeinFlip ease-out 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinFlip ease-out 0.4s; /* Firefox < 16 */
  -ms-animation: fadeinFlip ease-out 0.4s; /* Internet Explorer */
  -o-animation: fadeinFlip ease-out 0.4s; /* Opera < 12.1 */
  animation: fadeinFlip ease-out 0.4s;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
    transform 1s ease-in-out;

  &:hover {
    background: rgba(119, 0, 255, 0.88);
    color: white;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export default function ProjetNavbar({ data, toStop }) {

  const [isSector, setIsSector] = useState(null);

  const viewportContext = createContext({})

  const ViewportProvider = ({ children }) => {
    const isBrowser = typeof window !== "undefined"

      const [width, setWidth] = useState(window.innerWidth)

      const handleWindowRezise = () => {
        setWidth(window.innerWidth)
      }
    
      useEffect(() => {
        window.addEventListener("resize", handleWindowRezise)
        return () => window.removeEventListener("resize", handleWindowRezise)
      }, [])

      return (
        <viewportContext.Provider value={{ width }}>
          {children}
        </viewportContext.Provider>
      )
    
  }

  const useViewport = () => {
    const { width } = useContext(viewportContext)
    return { width }
  }

  const MobileComponent = () => <LogotypeMini />
  const DesktopComponent = () => <Icon />

  const MyComponent = () => {
    const { width } = useViewport()
    const breakpoint = 768

    return width < breakpoint ? <MobileComponent /> : <DesktopComponent />
  }

  let toSector = data.contentfulProjects.sector;

  useEffect(() => {
    handleSector();
  }, [])


  function handleSector() {
    if(toSector.includes("Santé")) {
      setIsSector(toSector.replace("Santé", "sante").toLowerCase());
    } else if(toSector.includes("Événementiel")) {
      setIsSector(toSector.replace("Événementiel", "evenementiel").toLowerCase());
    } else {
      setIsSector(toSector.toLowerCase());
    }
  }

  return (
    <ViewportProvider>
      <ProjetHeader data={data} className={AnimationsStyle.fadeNavBar}>
        <ProjetNav>
          <Logo to="/" aria-label="Perroquet">
            <MyComponent />
          </Logo>

          <NomProjet id="AA">
            {data.contentfulProjects.title && (
              <span>{data.contentfulProjects.title}</span>
            )}
          </NomProjet>

          {data.contentfulProjects.category.map(categorie => {

            let toCategorie = categorie;
            let toThisCat = toCategorie;

            if(toCategorie.includes("Identité visuelle")){
              toThisCat = toCategorie.replace("Identité visuelle", "identiteVisuelle");
            }
            if(toCategorie.includes("Application mobile")){
              toThisCat = toCategorie.replace("Application mobile", "applicationMobile");
            }
            if(toCategorie.includes("Site web")){
              toThisCat = toCategorie.replace("Site web", "siteWeb");
            }
            if(toCategorie.includes("Illustration")){
              toThisCat = toCategorie.replace("Illustration", "illustration");
            }
            if(toCategorie.includes("Animation")){
              toThisCat = toCategorie.replace("Animation", "animation");
            }

            return (
              <AnchorCategory
                key={categorie}
                to={`/portfolio/#${toThisCat}`}
                title={"renvoie vers la liste des projets par " + categorie}
                state={{ filtre: `${categorie}` }}
              >
                {data.contentfulProjects.title && <span>{categorie}</span>}
              </AnchorCategory>
            )
          })}

          <AnchorCategory
            to={`/portfolio/#${isSector}`}
            title={
              "renvoie vers la liste des projets par " +
              data.contentfulProjects.sector
            }
            state={{ sector: data.contentfulProjects.sector }}
          >
            {data.contentfulProjects.sector && (
              <span>{data.contentfulProjects.sector}</span>
            )}
          </AnchorCategory>
        </ProjetNav>

        <AnchorClose to="/portfolio" onClick={toStop}>x</AnchorClose>
      </ProjetHeader>
    </ViewportProvider>
  )
}
