import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"

import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
import { GatsbyImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/all"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import ProgressGsap from "../ProgressBar/ProgressGsap"

import ImageDeFond from "../../img/fond/Perroquet-fond-V.jpg"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const HomeContainer = styled.section`
  height: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`

const GradientMask = styled.div`
  position: absolute;
  width: 100vw;
  height: 300px;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(10, 10, 10, 0), rgba(0, 0, 0, 0.9));
`

const ProjectContainer = styled.main`
  width: 600%;
  height: 100vh;
  display: flex;
  flex-wrap: no-wrap;
`

const ProjetSlideMain = styled.section`
  color: white;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
`
const ProjetSlideSlogan = styled.h1`
  z-index: 2;
  width: 80%;
  max-width: 900px;
  color: white;
  font-size: 2.5rem !important;
  line-height: normal !important;
  padding-left: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 3rem !important;
  }
`
const ProjetSlideBtn = styled.a`
  z-index: 2;
  width: fit-content;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1rem 20px 0;
  z-index: 1001;
  text-decoration: none;

  #slideBtnSpan {
    display: none;
    width: 220px;
  }

  @media screen and (min-width: 768px) {
    #slideBtnSpan {
      display: inherit;
    }
    #slideBtnSpanMobile {
      display: none;
    }
  }
`
const ProjetSlideBtnSpan = styled.span`
  font-family: "Perroquet-Regular", Arial, Helvetica, sans-serif;
  cursor: pointer;
  padding: 0.2rem 0.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10pt;
  place-items: center;
  align-self: flex-end;
  color: rgba(255, 255, 255, 0.88);
  background-color: rgba(46, 47, 50, 0.88);
  border-radius: 1.75px;
  z-index: 1;
  min-width: 17% !important;
  margin-bottom: 3%;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: #7600ff;
  }
`

const ProjetSlideContentTwo = styled.article`
  height: 100vh;
  width: 50vw;
  min-width: 32rem;
  display: grid;
  place-content: center;
  background-image: url(${ImageDeFond});
  position: relative;
`
const ProjetSlideDateContainer = styled.section`
  border-bottom: 1px solid rgba(119, 0, 255, 0.88);
  padding: 1rem 1rem;
  min-width: 10rem;
`
const ProjetSlideDateParagraphTitle = styled.p`
  font-family: "Perroquet-Regular", Arial, Helvetica, sans-serif;
  font-size: 12pt !important;
  color: #777;
  text-transform: uppercase;
`
const ProjetSlideDateParagraph = styled.p`
  font-size: 12pt !important;
  color: #ffffff;
`
const ProjetKnowMore = styled.button`
  display: block;
  border: 1px solid #777;
  background-color: rgba(0, 0, 0, 0.88);
  padding: 0.2rem 0.6rem;
  border-radius: 30px;
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.88);
  cursor: pointer;

  position: absolute;
  bottom: 3em;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.75s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.88) !important;
    color: black;
    border: 1px solid #e0e0e0 !important;
  }

  &:active {
    background-color: #7600ff !important;
    border: none !important;
    font-weight: bold;
    color: white !important;
  }

  &:focus {
    background-color: #7600ff !important;
    color: white !important;
    border: none !important;
    box-shadow: none;
    font-weight: bold;
  }
`

const GatsbyImgWrapper = styled.article`
  display: flex;
  height: 100vh;
`
/*
const GatsbyImgPortrait = styled.img`
  width: calc(100vh * 0.7);
  min-width: calc(100vh * 0.7);
  height: 100vh;
` */
const GatsbyImgPaysage = styled.img`
  width: calc(100vh * 1.5);
  min-width: calc(100vh * 1.5);
  height: 100vh;
  object-fit: cover;
`

const GastbyVideoWrapper = styled.article`
  width: auto;
  height: 100vh;
  display: grid;
  placeitems: center;

  video {
    width: calc(100vh * 1.5); 
    minWidth: calc(100vh * 1.5);
    height: 100vh;
  }
`
const ProjetSlideContentLast = styled.article`
  height: 100vh;
  width: 50vw;
  min-width: 32rem;
  display: grid;
  place-content: center;
  background-image: url(${ImageDeFond});
  position: relative;
`

const ProjetGoToNext = styled(Link)`
  text-decoration: none;
  font-size: 12pt !important;
  color: #ffffff;
  cursor: pointer;
`;


const ProjetGsap = ({ intl, data, toggleText, langue }) => {
  const projetWrapper = useRef(null)
  const sloganRef = useRef(null)
  const sloganBtnRef = useRef(null)

  const [sectionsHook, setSectionsHook] = useState(undefined)
  var maxWidth = 0

  var sections = undefined
  useEffect(() => {
    if (projetWrapper !== null) {
      const anchorTo = document.getElementById("scrollTo")

      sections = gsap.utils.toArray(".sectionTrigger")

      const getMaxWidth = () => {
        maxWidth = 0
        let idCounter = 0

        sections.forEach(section => {
          if (section.id === "mainSection") {
            idCounter++
          }
          if (idCounter <= 1) {
            maxWidth += section.offsetWidth
          }
        })
        setSectionsHook(maxWidth)
      }
      if (!sectionsHook) {
        getMaxWidth()
      }

      ScrollTrigger.addEventListener("refreshInit", getMaxWidth)

      gsap.from(sloganRef.current, {
        x: -200,
        opacity: 0,
        ease: "slowMo",
        duration: 1.2,
      })
      gsap.from(sloganBtnRef.current, {
        y: 200,
        opacity: 0,
        ease: "slowMo",
        duration: 1.2,
      })

      gsap.to(sections, {
        x: () => `-${maxWidth - window.innerWidth}`,
        ease: "none",
        scrollTrigger: {
          trigger: projetWrapper.current,
          pin: true,
          scrub: true,
          end: () => `+=${maxWidth}`,
          invalidateOnRefresh: true,
        },
      })

      anchorTo.addEventListener("click", function (e) {
        e.preventDefault()
        gsap.to(window, {
          // scrollTo: (document.getElementById("infosProjets").offsetLeft + 100),
          scrollTo:
            document.getElementById("infosProjets").offsetLeft *
            (document.querySelector("#page").offsetWidth /
              (document.querySelector("#page").offsetWidth -
                window.innerWidth)),
        })
      })

      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: {
          scrub: 0.3,
          end: () => `+=${maxWidth}`,
        },
      })
    }
  }, [])

  const navigatePage = (slug, cond) => {
    if (cond === "pas de navigate") {
      this.setState({ isBtn: "true" })
      return this.filtre(slug)
    }

    if (cond === "nav") {
      return navigate(slug, "refresh")
    }
  }

  return (
    <HomeContainer data={data} toggleText={toggleText}>
      <ProjectContainer ref={projetWrapper} id="page">
        <ProjetSlideMain
          alt={data.contentfulProjects.mainPicture.title}
          Tag="section"
          className="sectionTrigger"
          id="mainSection"
        >
          <GradientMask />
          <GatsbyImage
            image={data.contentfulProjects.mainPicture.gatsbyImageData}
            src={`${data.contentfulProjects.mainPicture.gatsbyImageData}`}
            //data.contentfulProjects.mainPicture.file.url
            alt={data.contentfulProjects.mainPicture.title}
            style={{ position: "absolute", width: "100vw", height: "100vh" }}
          />
          <ProjetSlideSlogan ref={sloganRef}>
            {data.contentfulProjects.slogan}
          </ProjetSlideSlogan>

          <ProjetSlideBtn
            ref={sloganBtnRef}
            id="scrollTo"
            href="#infosProjets"
            rel="noreferrer"
          >
            <ProjetSlideBtnSpan id="slideBtnSpan">
              {intl.formatMessage({ id: "decouvrez" })} &#8594;
            </ProjetSlideBtnSpan>
            <ProjetSlideBtnSpan id="slideBtnSpanMobile">
              &#8594;
            </ProjetSlideBtnSpan>
          </ProjetSlideBtn>
        </ProjetSlideMain>
        <ProjetSlideContentTwo id="infosProjets" className="sectionTrigger">
          <ProjetSlideDateContainer>
            <ProjetSlideDateParagraphTitle>
              {intl.formatMessage({ id: "date" })}
            </ProjetSlideDateParagraphTitle>
            <ProjetSlideDateParagraph>
              {data.contentfulProjects.year}
            </ProjetSlideDateParagraph>
          </ProjetSlideDateContainer>

          <ProjetSlideDateContainer>
            <ProjetSlideDateParagraphTitle>
              {intl.formatMessage({ id: "location" })}
            </ProjetSlideDateParagraphTitle>
            <ProjetSlideDateParagraph>
              {data.contentfulProjects.location}
            </ProjetSlideDateParagraph>
          </ProjetSlideDateContainer>

          <ProjetSlideDateContainer>
            <ProjetSlideDateParagraphTitle>
              {intl.formatMessage({ id: "client" })}
            </ProjetSlideDateParagraphTitle>
            <ProjetSlideDateParagraph>
              {data.contentfulProjects.client}
            </ProjetSlideDateParagraph>
          </ProjetSlideDateContainer>

          {data.contentfulProjects.partenaire && (
            <ProjetSlideDateContainer>
              <ProjetSlideDateParagraph>
                {intl.formatMessage({ id: "partenaires" })}
              </ProjetSlideDateParagraph>
              <ProjetSlideDateParagraph>
                {data.contentfulProjects.partenaire}
              </ProjetSlideDateParagraph>
            </ProjetSlideDateContainer>
          )}

          <ProjetKnowMore onClick={() => toggleText()}>
            En savoir plus
          </ProjetKnowMore>
        </ProjetSlideContentTwo>

        {data.contentfulProjects.images &&
          data.contentfulProjects.images.map((image, index) => {
            if (image && image.gatsbyImageData) {
              return (
                <GatsbyImgWrapper key={index}>
                  {image.gatsbyImageData.width === 1400 && (
                    <div className="sectionTrigger">
                      <GatsbyImage
                        style={{
                          width: "calc(100vh * 0.7)",
                          minWidth: "calc(100vh * 0.7)",
                          height: "100vh",
                        }}
                        image={image.gatsbyImageData}
                        // src={`${image.gatsbyImageData}`}
                        alt={image.title}
                      />
                      {/*
                      <GatsbyImgPortrait
                        src={"https:" + image.gatsbyImageData}
                        alt={image.title}
                      />*/}
                    </div>
                  )}
                  {image.gatsbyImageData.width === 3000 && (
                    <div className="sectionTrigger" key={index}>
                      <GatsbyImage
                        style={{
                          width: "calc(100vh * 1.5)",
                          minWidth: "calc(100vh * 1.5)",
                          height: "100vh",
                          objectFit: "cover",
                        }}
                        image={image.gatsbyImageData}
                        // src={`${image.gatsbyImageData}`}
                        alt={image.title}
                      />
                      
                      {/* <GatsbyImgPaysage
                        src={"https:" + `${JSON.stringify(image.gatsbyImageData)}`}
                        alt={image.title}
                      /> */}
                    </div>
                  )}
                </GatsbyImgWrapper>
              )
            } else {
              return (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "row", width:"fit-content" }}
                  className="sectionTrigger"
                >
                  <GastbyVideoWrapper>
                    <video src={"https:" + image.file.url} playsInline autoPlay loop muted></video>
                  </GastbyVideoWrapper>
                </div>
              )
            }
          })}
        {data.contentfulProjects.slugProchainProjet && (
          <ProjetSlideContentLast
            id="infosProjets"
            className="sectionTrigger"
            // onClick={() => {
            //   navigatePage(
            //     `/${data.contentfulProjects.slugProchainProjet}`,
            //     "nav"
            //   )
            // }}
            // to={`/${data.contentfulProjects.slugProchainProjet}`}
            // langue={langue}
          >
            <ProjetSlideDateContainer>
              <ProjetSlideDateParagraphTitle>
                {intl.formatMessage({ id: "prochainProjet" })}
              </ProjetSlideDateParagraphTitle>
              {/* <ProjetSlideDateParagraph>
                {data.contentfulProjects.titreProchainProjet}
              </ProjetSlideDateParagraph> */}
              <ProjetGoToNext 
                to={`/${data.contentfulProjects.slugProchainProjet}`}
                langue={langue} 
                reloaddocument="true">
                {data.contentfulProjects.titreProchainProjet}
              </ProjetGoToNext>
            </ProjetSlideDateContainer>
          </ProjetSlideContentLast>
        )}
      </ProjectContainer>

      <ProgressGsap />
    </HomeContainer>
  )
}

export default injectIntl(ProjetGsap)
